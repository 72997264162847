import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import _ from 'lodash'
import PropTypes from "prop-types"
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import Link from 'gatsby-link'
import locations from '../utils/locations'
import slugify from 'slugify'
import withConfig from '../wrappers/withConfig'


const styles = {

}

const InnerComponent = ({ data, location, keyServiceName, ...otherProps }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <PageTitle title={frontmatter.title}/>
      <div css={[themeStyles.contentWidth]}>
        <div css={themeStyles.textPadding}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
      {frontmatter.showCareerForm &&
        <div css={[themeStyles.contentWidth]}>
          <div css={[themeStyles.textPadding, {display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}]}>
            <ContactForm formName='Career'/>
          </div>
        </div>
      }
      {frontmatter.showLocations &&
        <div css={[themeStyles.contentWidth]}>
          <div css={[themeStyles.textPadding]}>
            <ul>
              {_.map(locations, l => (<li><Link key={keyServiceName} to={'/locations/' + slugify(l.toLowerCase())}>{keyServiceName} in {l}, UT</Link></li>))}
            </ul>
          </div>
        </div>
      }
    </Layout>
  )
}

const Wrapped = withConfig(InnerComponent)


const OtherPage = (props) => {
  return <Wrapped {...props} />
}

OtherPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default OtherPage

export const pageQuery = graphql`
  query OtherPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        showCareerForm
        showLocations
      }
    }
  }
`
